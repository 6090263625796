.ReactCollapse--collapse {
    transition: height 500ms;
}

.background-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/background.png');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 0;
}

.content {
    position: relative;
    z-index: 1;
}
